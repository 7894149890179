.header th,
.row td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Space Mono', monospace;
}

.header th {
    font-weight: bold;
    font-size: 14px;
    color: #666666;
    padding: 15px 25px;
    text-align: left;
    background-color: #F5F5F5;
    border-bottom: none;
    font-family: 'Inter', sans-serif;
}

.header th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.header th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.row td {
    font-size: 14px;
    color: #666666;
    padding: 20px 25px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}

.row td:hover {
  cursor: pointer;
}
